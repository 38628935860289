import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  TextField,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { patchAttendantApi, postAttendantApi } from '../../api/attendants';

export const NewAttendantForm = ({
  newAttendant = true,
  attendant,
  handleSuccess,
  branchId,
}) => {
  const [modified, setModified] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      status: true,
      submit: null,
    },
    validationSchema: Yup.object({
      name: Yup.string().max(255).required('Inserta Nombre'),
      status: Yup.boolean(),
    }),
    onSubmit: async (values, helpers) => {
      const dataToPost = {
        ...values,
        branchId: branchId,
      };
      if (!newAttendant) {
        delete dataToPost.branchId;
      }
      newAttendant
        ? console.log('newAttendant', dataToPost)
        : console.log('modifiedAttendant', dataToPost);
      try {
        newAttendant
          ? await postAttendantApi(dataToPost)
          : await patchAttendantApi(attendant.id, dataToPost);
        handleSuccess();
        setModified(false);
        helpers.resetForm();
      } catch (error) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: error.message });
        helpers.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (!newAttendant) {
      formik.setValues({
        name: attendant.name ? attendant.name : '',
        status: attendant.status ? attendant.status : false,
        submit: null,
      });
    }
    // eslint-disable-next-line
  }, [attendant, newAttendant]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Card>
        <CardHeader
          title={newAttendant ? 'Nuevo Asistente' : 'Modificar Asistente'}
        />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid container spacing={3}>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Nombre del o de la asistente"
                  name="name"
                  error={!!(formik.touched.name && formik.errors.name)}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.handleChange(e);
                    setModified(true);
                  }}
                  value={formik.values.name}
                />
              </Grid>
              {!newAttendant && (
                <Grid xs={12} md={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.status}
                        value={formik.values.status}
                        name="status"
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          formik.handleChange(e);
                          setModified(true);
                        }}
                      />
                    }
                    label="Activo"
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          {newAttendant && (
            <Button variant="contained" type="submit">
              Crear Asistente
            </Button>
          )}
          {!newAttendant && modified && (
            <Button variant="contained" type="submit">
              Modificar Asistente
            </Button>
          )}
        </CardActions>
      </Card>
    </form>
  );
};
