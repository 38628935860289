import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    branches: [],
    business: {},
    currentBranch: {},
    isAuthenticated: false,
    isLoading: true,
    user: {},
  },
  reducers: {
    onChecking: (state /* action */) => {
      state.branches = [];
      state.business = {};
      state.currentBranch = {};
      state.isAuthenticated = false;
      state.isLoading = true;
      state.user = {};
    },
    onLogin: (state, { payload }) => {
      state.isAuthenticated = true;
      state.isLoading = false;
      state.user = payload;
    },
    onAddBranches: (state, { payload }) => {
      state.branches = payload;
    },
    onAddBusiness: (state, { payload }) => {
      state.business = payload;
    },
    onChangeCurrentBranch: (state, { payload }) => {
      state.currentBranch = payload;
    },
    onLogout: (state /* action */) => {
      state.branches = [];
      state.business = {};
      state.currentBranch = {};
      state.isAuthenticated = false;
      state.isLoading = false;
      state.user = {};
    },
  },
});

export const {
  onAddBranches,
  onAddBusiness,
  onChangeCurrentBranch,
  onChecking,
  onLogin,
  onLogout,
} = authSlice.actions;
