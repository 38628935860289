import { Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from './Dashboard';
import Groups from './Groups';
import Reports from './Reports';
import Tables from './Tables';
import NewReview from './NewReview';
import BranchHydration from '../utils/BranchHydration';
import { AdminPanelRoutes } from './adminPanel/AdminPanelRoutes';
import { PrivateRoute } from '../router/PrivateRoute';
import PricingPage from './PricingPage';
import CurrentPlan from './CurrentPlan';
import MyInfo from './MyInfo';

export const AppRoutes = () => (
  <Routes>
    <Route
      path="dashboard"
      element={
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      }
    />
    <Route
      path="groups"
      element={
        <PrivateRoute>
          <Groups />
        </PrivateRoute>
      }
    />
    <Route
      path="reports"
      element={
        <PrivateRoute>
          <Reports />
        </PrivateRoute>
      }
    />
    <Route
      path="tables"
      element={
        <PrivateRoute>
          <Tables />
        </PrivateRoute>
      }
    />
    <Route
      path="adminPanel/*"
      element={
        <PrivateRoute>
          <AdminPanelRoutes />
        </PrivateRoute>
      }
    />
    <Route
      path="myInfo/*"
      element={
        <PrivateRoute>
          <MyInfo />
        </PrivateRoute>
      }
    />
    <Route
      path="pricing/*"
      element={
        <PrivateRoute>
          <PricingPage />
        </PrivateRoute>
      }
    />
    <Route
      path="currentPlan/*"
      element={
        <PrivateRoute>
          <CurrentPlan />
        </PrivateRoute>
      }
    />

    <Route path="newReview" element={<BranchHydration />} />
    <Route path="newReview/:branchId" element={<NewReview />} />

    <Route path="/*" element={<Navigate to="dashboard" />} />
  </Routes>
);
