import axios from 'axios';
import { getToken } from '../utils/token';

const envUrl = process.env.REACT_APP_API_BASE_URL;

export async function getAttendantsByBranchApi(id, status = []) {
  let url = `${envUrl}/attendants/branch/${id}/`;
  if (status.length) {
    url += `?status=${JSON.stringify(status)}`;
  }

  const token = await getToken();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  return axios
    .get(url, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
}

export async function getAttendantByIdApi(id) {
  const url = `${envUrl}/attendants/${id}`;
  const token = await getToken();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  return axios
    .get(url, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
}

export async function postAttendantApi(data) {
  const url = `${envUrl}/attendants`;
  const token = await getToken();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  return axios
    .post(url, data, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
}

export async function patchAttendantApi(id, data) {
  const url = `${envUrl}/attendants/${id}`;
  const token = await getToken();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  return axios
    .patch(url, data, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
}

export async function deleteAttendantApi(id) {
  const url = `${envUrl}/attendants/${id}`;
  const token = await getToken();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  return axios
    .delete(url, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
}
