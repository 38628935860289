import PropTypes from 'prop-types';
import ArchiveBoxIcon from '@heroicons/react/24/solid/ArchiveBoxIcon';
import {
  Avatar,
  Card,
  CardContent,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material';

export const TotalReviews = ({ reviewsTotal, sx }) => {
  return (
    <Card sx={sx}>
      <CardContent>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography color="text.secondary" variant="overline">
              Total de Reseñas
            </Typography>
            <Typography variant="h4">{reviewsTotal}</Typography>
          </Stack>
          <Avatar
            sx={{
              backgroundColor: 'primary.main',
              height: 56,
              width: 56,
            }}
          >
            <SvgIcon>
              <ArchiveBoxIcon />
            </SvgIcon>
          </Avatar>
        </Stack>
      </CardContent>
    </Card>
  );
};

TotalReviews.propTypes = {
  reviewsTotal: PropTypes.number.isRequired,
  sx: PropTypes.object,
};
