import { format } from 'date-fns';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Scrollbar } from '../../components/scrollbar';
import { useEffect, useState } from 'react';
import { dayOfWeekMapper } from '../../utils/dayOfWeekMapper';
import { getReviewsByTimeApi } from '../../api/reviews';

export const ReviewsByDateTable = ({ sx, startDate, endDate, branchId }) => {
  const [groupedBySentiment, setGroupedBySentiment] = useState({});

  useEffect(() => {
    const fetchData = async (start, end) => {
      try {
        const data = await getReviewsByTimeApi(
          branchId,
          start,
          end,
          'sentiment',
          'date'
        );
        setGroupedBySentiment(data);
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };
    if (startDate !== '' && endDate !== '' && branchId) {
      fetchData(startDate, endDate);
    }
  }, [startDate, endDate, branchId]);

  return (
    <Card sx={sx}>
      <CardHeader title="Resumen" />
      <Scrollbar sx={{ flexGrow: 1 }}>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sortDirection="desc">Fecha</TableCell>
                <TableCell>Positivo</TableCell>
                <TableCell>Negativo</TableCell>
                <TableCell>Neutral</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(groupedBySentiment).map((dateTag) => {
                const date = format(new Date(dateTag), 'dd/MM/yyyy');
                const dayOfTheWeek = format(new Date(dateTag), 'EEEE');
                return (
                  <TableRow hover key={dateTag}>
                    <TableCell>
                      {dayOfWeekMapper[dayOfTheWeek]}, {date}
                    </TableCell>
                    <TableCell>
                      {groupedBySentiment[dateTag]['Positivo']}
                    </TableCell>
                    <TableCell>
                      {groupedBySentiment[dateTag]['Negativo']}
                    </TableCell>
                    <TableCell>
                      {groupedBySentiment[dateTag]['Neutral']}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <Divider />
    </Card>
  );
};

ReviewsByDateTable.prototype = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  branchId: PropTypes.number,
  sx: PropTypes.object,
};
