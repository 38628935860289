import { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { patchUserByIdApi } from '../../api/users';
import { useAuthStore } from '../../hooks/useAuthStore';
import { useNavigate } from 'react-router-dom';

export const ChangePasswordForm = ({ user, handleSuccess }) => {
  const [modified, setModified] = useState(false);
  const { startLogout } = useAuthStore();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordVal: '',
      submit: null,
    },

    validationSchema: Yup.object({
      password: Yup.string()
        .max(255)
        .min(8, 'Mínimo 8 cacrcteres')
        .required('Inserta Contraseña'),
      passwordVal: Yup.string()
        .max(255)
        .min(8, 'Mínimo 8 cacrcteres')
        .required('Inserta Contraseña de Validación'),
      status: Yup.boolean(),
    }),
    onSubmit: async (values, helpers) => {
      if (values.password !== values.passwordVal) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: 'Password Validation Failed' });
        helpers.setSubmitting(false);
      }
      const dataToPost = { ...values };
      delete dataToPost.passwordVal;
      try {
        await patchUserByIdApi(user.id, dataToPost);
        setModified(false);
        handleSuccess();
        setTimeout(async () => {
          await startLogout();
          navigate('/login');
        }, 1500);
      } catch (error) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: error.message });
        helpers.setSubmitting(false);
      }
    },
  });

  const handleChange = () => {
    setModified(true);
  };

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <Card>
        <CardHeader title="Cambio de Contraseña" />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid container spacing={3}>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Contraseña"
                  name="password"
                  type="password"
                  error={!!(formik.touched.password && formik.errors.password)}
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleChange();
                  }}
                  value={formik.values.password}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Contraseña Validación"
                  name="passwordVal"
                  type="password"
                  error={
                    !!(formik.touched.passwordVal && formik.errors.passwordVal)
                  }
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleChange();
                  }}
                  value={formik.values.passwordVal}
                />
              </Grid>
              {formik.errors.submit && (
                <Typography color="error" sx={{ mt: 3 }} variant="body2">
                  {formik.errors.submit}
                </Typography>
              )}
            </Grid>
          </Box>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          {modified && (
            <Button variant="contained" type="submit">
              Modificar Usuario
            </Button>
          )}
        </CardActions>
      </Card>
    </form>
  );
};
