import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuthStore } from '../hooks/useAuthStore';
import { useLocation } from 'react-router-dom';

export const OwnerConditionalRendering = ({ children }) => {
  const location = useLocation();
  const { user } = useAuthStore();
  const [isOwner, setIsOwner] = useState(false);

  useEffect(() => {
    if (user.role === 'OWNER' && user.id) {
      setIsOwner(true);
    }
  }, [location.pathname, user]);

  if (!isOwner) return null;
  return children;
};

OwnerConditionalRendering.propTypes = {
  children: PropTypes.node,
};
