import { Layout as DashboardLayout } from '../../layouts/dashboard/layout';
import {
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { NewAttendantForm } from '../../components/forms/new-attendant-form';
import { deleteAttendantApi, getAttendantByIdApi } from '../../api/attendants';
import { ToastContainer } from 'react-toastify';
import { ToastSuccessSync } from '../../components/shared/Toast';
import { useNavigate, useParams } from 'react-router-dom';
import TrashIcon from '@heroicons/react/24/solid/TrashIcon';
import ConfirmationModal from '../../components/modals/confirmationModal';

const Attendant = () => {
  const { attendantId } = useParams();
  const navigate = useNavigate();

  const [attendant, setAttendant] = useState({});
  const [triggerFetch, setTriggerFetch] = useState(false);
  const lastCalledRef = useRef(0);

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const handleOpenConfirmationModal = () => setConfirmationModalOpen(true);
  const handleCloseConfirmationModal = () => setConfirmationModalOpen(false);

  const handleSuccess = () => {
    ToastSuccessSync('Procedimiento Exitoso');
    setTriggerFetch((prevState) => !prevState);
  };

  const handleSuccessDelete = async () => {
    const sleep = (milliseconds) => {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    };
    handleCloseConfirmationModal();
    try {
      await deleteAttendantApi(attendantId);
      ToastSuccessSync('Procedimiento Exitoso');
      sleep(1000).then(() => {
        navigate(`/adminPanel/branches/${attendant.branchId}`);
      });
    } catch (error) {
      console.error('Error deleting attendant:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAttendantByIdApi(attendantId);
        setAttendant(data);
      } catch (error) {
        console.error('Error fetching attendant:', error);
      }
    };
    const now = Date.now();
    if (attendantId && (now - lastCalledRef.current > 5000 || triggerFetch)) {
      lastCalledRef.current = now;
      fetchData();
    }
  }, [triggerFetch, attendantId]);

  return (
    <DashboardLayout>
      <ToastContainer />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">{attendant.name}</Typography>
              </Stack>
              <Button
                startIcon={
                  <SvgIcon fontSize="small">
                    <TrashIcon />
                  </SvgIcon>
                }
                sx={{ bgcolor: 'error.main', color: 'white' }}
                variant="contained"
                onClick={handleOpenConfirmationModal}
              >
                Borrar Asistente
              </Button>
            </Stack>
            <NewAttendantForm
              newAttendant={false}
              attendant={attendant}
              handleSuccess={handleSuccess}
            />
          </Stack>
        </Container>
      </Box>
      <ConfirmationModal
        open={confirmationModalOpen}
        confirmationText={`¿Estás seguro que deseas borrar al asistente ${attendant.name}? Toda la información relacionada con este será eliminada sin excepción.`}
        handleClose={handleCloseConfirmationModal}
        handleSuccess={handleSuccessDelete}
      />
    </DashboardLayout>
  );
};

export default Attendant;
