import { Route, Routes } from 'react-router-dom';
import AdminPanel from './AdminPanel';
import Branch from './Branch';
import Attendant from './Attendant';
import Spot from './Spot';

export const AdminPanelRoutes = () => (
  <Routes>
    <Route path="/*" element={<AdminPanel />} />
    <Route path="branches/:branchId" element={<Branch />} />
    <Route path="attendant/:attendantId" element={<Attendant />} />
    <Route path="spot/:spotId" element={<Spot />} />
  </Routes>
);
