import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Chart } from '../../components/chart';
import Button from '@mui/material/Button';
import { useEffect, useMemo, useState } from 'react';
import { sentiments } from '../../utils/sentiments';
import { getReviewsByTimeApi } from '../../api/reviews';

const useChartOptions = () => {
  const theme = useTheme();

  return {
    chart: {
      background: 'transparent',
      stacked: false,
      toolbar: {
        show: true,
      },
    },
    colors: sentiments.colors,
    dataLabels: {
      enabled: false,
    },
    fill: {
      opacity: 1,
      type: 'solid',
    },
    stroke: {
      curve: 'smooth',
    },
    grid: {
      borderColor: theme.palette.divider,
      strokeDashArray: 2,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    legend: {
      show: true,
    },
    theme: {
      mode: theme.palette.mode,
    },
    xaxis: {
      axisBorder: {
        color: theme.palette.divider,
        show: true,
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true,
      },
      categories: [
        'Ene',
        'Feb',
        'Mar',
        'Abr',
        'May',
        'Jun',
        'Jul',
        'Ago',
        'Sep',
        'Oct',
        'Nov',
        'Dic',
      ],
      labels: {
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
    yaxis: {
      title: {
        text: 'Número de Reseñas',
        offsetX: 5,
      },
      labels: {
        formatter: (value) => `${value}`,
        offsetX: -10,
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
  };
};

export const ReviewsYear = ({ sx, startDate, endDate, branchId }) => {
  const [type, setType] = useState('line');
  const chartOptions = useChartOptions();
  const [groupedBySentiment, setGroupedBySentiment] = useState({});

  const chartSeries = useMemo(() => {
    const initial = sentiments.tags.map((sentiment) => {
      return {
        name: sentiment,
        data: [],
      };
    });

    if (!Object.keys(groupedBySentiment).length) return initial;

    for (let i = 0; i < 12; i++) {
      sentiments.tags.forEach((sentiment) => {
        const objectToModify = initial.find((obj) => obj.name === sentiment);
        const val = groupedBySentiment[i][sentiment]
          ? groupedBySentiment[i][sentiment]
          : 0;
        objectToModify.data.push(val);
      });
    }

    return initial;
  }, [groupedBySentiment]);

  useEffect(() => {
    const fetchData = async (start, end) => {
      try {
        const data = await getReviewsByTimeApi(
          branchId,
          start,
          end,
          'sentiment',
          'month'
        );
        setGroupedBySentiment(data);
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };
    if (startDate !== '' && endDate !== '' && branchId) {
      fetchData(startDate, endDate);
    }
  }, [startDate, endDate, branchId]);

  return (
    <Card sx={sx}>
      <CardHeader title="Reseñas por mes" />
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="left"
        sx={{ my: 2, ml: 2 }}
        spacing={{ xs: 1, sm: 2 }}
        useFlexGap
        flexWrap="wrap"
      >
        <Button
          variant="contained"
          onClick={() =>
            setType((prevState) => (prevState === 'line' ? 'bar' : 'line'))
          }
        >
          {type === 'line' ? 'Linea' : 'Barras'}
        </Button>
      </Stack>

      <CardContent>
        <Chart
          height={350}
          options={chartOptions}
          series={chartSeries}
          type={type}
          width="100%"
        />
      </CardContent>
    </Card>
  );
};

ReviewsYear.protoTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  branchId: PropTypes.number,
  sx: PropTypes.object,
};
