import { useEffect } from 'react';
import { useAuthStore } from '../hooks/useAuthStore';
import { useNavigate } from 'react-router-dom';

const BranchHydration = () => {
  const { currentBranch } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentBranch?.id) {
      window.open(`newReview/${currentBranch.id}`, '_blank');
      navigate('/');
    }
  }, [currentBranch, navigate]);

  return null;
};

export default BranchHydration;
