import { useDispatch, useSelector } from 'react-redux';
import {
  onAddBranches,
  onAddBusiness,
  onChangeCurrentBranch,
  onChecking,
  onLogin,
  onLogout,
} from '../store/auth/authSlice';
import { getToken, removeToken, setToken } from '../utils/token';
import { getBranchesByBusinessApi, getUserBranchesApi } from '../api/branch';
import { loginApi, signUpApi, userByTokenApi } from '../api/auth';
import { getBusinessByUserApi } from '../api/businesses';
import {
  createUserWithEmailAndPass,
  logoutFirebase,
  sendEmailVerif,
  signInUserWithEmailPassword,
} from '../firebase/providers';
import { FirebaseAuth } from '../firebase/config';
import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';

export const useAuthStore = () => {
  const {
    branches,
    business,
    currentBranch,
    isAuthenticated,
    isLoading,
    user,
  } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const startSignUp = async ({
    username,
    email,
    password,
    businessName,
    businessDescription,
    branchName,
    branchAddress,
    branchGroups,
  }) => {
    dispatch(onChecking());

    try {
      const response = await signUpApi(
        username,
        email,
        password,
        businessName,
        businessDescription,
        branchName,
        branchAddress,
        branchGroups
      );
      const token = response.accessToken;
      setToken(token);
    } catch (error) {
      throw new Error('Invalid Credentials');
    }

    try {
      await createUserWithEmailAndPass({ email, password });
    } catch (error) {
      throw new Error('Invalid firebase signup');
    }

    try {
      await sendEmailVerif();
    } catch (error) {
      throw new Error('Email Verfication Failed');
    }

    try {
      const user = await userByTokenApi();
      if (user.role === 'OWNER') {
        const business = await getBusinessByUserApi();
        const branches = await getBranchesByBusinessApi(business.id);
        dispatch(onAddBranches([...branches]));
        dispatch(onAddBusiness({ ...business }));
        dispatch(onChangeCurrentBranch({ ...branches[0] }));
      }
      if (user.role === 'MANAGER') {
        const branches = await getUserBranchesApi();
        const business = branches[0]?.business;
        dispatch(onAddBranches([...branches]));
        dispatch(onAddBusiness({ ...business }));
        dispatch(onChangeCurrentBranch({ ...branches[0] }));
      }
      if (user.role === 'ADMIN') {
        throw new Error('Error setting user');
      }
      dispatch(onLogin({ ...user }));
    } catch (error) {
      dispatch(onLogout());
      await logoutFirebase();
      throw new Error('Error setting user');
    }
  };

  const startLogin = async ({ email, password }) => {
    dispatch(onChecking());

    try {
      const response = await loginApi(email, password);
      const token = response.accessToken;
      setToken(token);
    } catch (error) {
      throw new Error('Invalid Credentials');
    }

    try {
      await signInUserWithEmailPassword({ email, password });
    } catch (error) {
      throw new Error('Invalid firebase login');
    }

    try {
      const user = await userByTokenApi();
      if (user.role === 'OWNER') {
        const business = await getBusinessByUserApi();
        const branches = await getBranchesByBusinessApi(business.id);
        dispatch(onAddBranches([...branches]));
        dispatch(onAddBusiness({ ...business }));
        dispatch(onChangeCurrentBranch({ ...branches[0] }));
      }
      if (user.role === 'MANAGER') {
        const branches = await getUserBranchesApi();
        const business = branches[0]?.business;
        dispatch(onAddBranches([...branches]));
        dispatch(onAddBusiness({ ...business }));
        dispatch(onChangeCurrentBranch({ ...branches[0] }));
      }
      dispatch(onLogin({ ...user }));
    } catch (error) {
      dispatch(onLogout());
      await logoutFirebase();
      throw new Error('Error setting user');
    }
  };

  const startLogout = async () => {
    removeToken();
    dispatch(onLogout());
    await logoutFirebase();
  };

  const checkAuthToken = async () => {
    const token = getToken();
    if (!token) return dispatch(onLogout());
    await logoutFirebase();

    try {
      const user = await userByTokenApi();
      if (user.role === 'OWNER') {
        const business = await getBusinessByUserApi();
        const branches = await getBranchesByBusinessApi(business.id);
        dispatch(onAddBranches([...branches]));
        dispatch(onAddBusiness({ ...business }));
        dispatch(onChangeCurrentBranch({ ...branches[0] }));
      }
      if (user.role === 'MANAGER') {
        const branches = await getUserBranchesApi();
        const business = branches[0]?.business;
        dispatch(onAddBranches([...branches]));
        dispatch(onAddBusiness({ ...business }));
        dispatch(onChangeCurrentBranch({ ...branches[0] }));
      }
      dispatch(onLogin({ ...user }));
    } catch (error) {
      dispatch(onLogout());
      await logoutFirebase();
      throw new Error('Error setting user');
    }
  };

  const updateCurrentUser = (user) => {
    dispatch(onLogin({ ...user }));
  };

  const updateCurrentBranch = (branch) => {
    dispatch(onChangeCurrentBranch({ ...branch }));
  };

  const updateBranches = (branches) => {
    dispatch(onAddBranches([...branches]));
  };

  const updateBusiness = (business) => {
    dispatch(onAddBusiness({ ...business }));
  };

  const [firebaseUser, setFirebaseUser] = useState({});
  const [firebaseLoading, setFirebaseLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(FirebaseAuth, (currentUser) => {
      if (currentUser) {
        currentUser.reload().then(() => {
          setFirebaseUser({
            uid: currentUser.uid,
            email: currentUser.email,
            isVerified: currentUser.emailVerified,
            displayName: currentUser.displayName,
          });
        });
      } else {
        setFirebaseUser(null);
      }
      setFirebaseLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return {
    // props
    branches,
    business,
    currentBranch,
    isAuthenticated,
    isLoading,
    user,
    firebaseUser,
    firebaseLoading,
    // methods
    checkAuthToken,
    startLogin,
    startLogout,
    startSignUp,
    updateCurrentBranch,
    updateBranches,
    updateBusiness,
    updateCurrentUser,
  };
};
