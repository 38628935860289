import { Route, Routes } from 'react-router-dom';
import LoginPage from '../auth/login';
import { AppRoutes } from '../app/AppRoutes';
import { PublicRoute } from './PublicRoute';
import SignInPage from '../auth/signin';
import ConfirmationPage from '../stripe/Confirmation';

export const AppRouter = () => {
  return (
    <>
      <Routes>
        <Route
          path="login"
          element={
            <PublicRoute>
              <LoginPage />
            </PublicRoute>
          }
        />

        <Route
          path="signin"
          element={
            <PublicRoute>
              <SignInPage />
            </PublicRoute>
          }
        />

        <Route
          path="stripeSessionCheckout/:sessionId"
          element={<ConfirmationPage />}
        />

        <Route path="*" element={<AppRoutes />} />
      </Routes>
    </>
  );
};
