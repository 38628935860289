import axios from 'axios';
import { getToken } from '../utils/token';

const envUrl = process.env.REACT_APP_API_BASE_URL;

export async function getStripeCustomerSessionIdApi() {
  const url = `${envUrl}/stripe/customer-session`;
  const token = await getToken();

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  return axios
    .get(url, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
}

export async function getStripeCheckoutSessionApi(sessionId) {
  const url = `${envUrl}/stripe/checkout-session/?sessionId=${sessionId}`;
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return axios
    .get(url, config)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw new Error(err);
    });
}
