import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Modal,
  Stack,
  Typography,
} from '@mui/material';
import { SeverityPill } from '../severity-pill';
import { useEffect, useMemo, useState } from 'react';
import { getRawReviewByIdApi } from '../../api/reviews';
import { dayOfWeekMapper } from '../../utils/dayOfWeekMapper';
import { format } from 'date-fns';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  p: 4,
};

const statusMap = {
  Neutral: 'warning',
  Positivo: 'success',
  Negativo: 'error',
};

export default function ReviewModal({ open, handleClose, parentReviewId }) {
  const [parentReview, setParentReview] = useState({});

  const memoizedDate = useMemo(() => {
    if (parentReview.createdAt) {
      const date = format(new Date(parentReview.createdAt), 'dd/MM/yyyy HH:mm');
      const dayOfTheWeek = format(new Date(parentReview.createdAt), 'EEEE');
      return `${dayOfWeekMapper[dayOfTheWeek]}, ${date}`;
    }
  }, [parentReview]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getRawReviewByIdApi(parentReviewId);
        setParentReview(data);
      } catch (error) {
        console.error('Error fetching parentReview:', error);
      }
    };
    if (parentReviewId) {
      fetchData();
    }
  }, [parentReviewId]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Card>
          <CardHeader subheader="Detalles de reseña" title="Reseña" />
          <CardContent sx={{ py: 0 }}>
            <Stack spacing={1}>
              <Typography>
                <b>Original:</b> <i>&quot;{parentReview.review}&quot;</i>
              </Typography>
              <Typography>
                <b>Fecha:</b> {memoizedDate}
              </Typography>
              <Typography variant="overline">Subreseñas</Typography>
              {parentReview.processedReviews &&
                parentReview.processedReviews.map((review) => (
                  <Stack
                    key={review.id}
                    useFlexGap
                    flexWrap="wrap"
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                    sx={{
                      border: '1px solid',
                      borderRadius: '5px',
                      padding: '15px',
                    }}
                  >
                    <Typography>
                      <b>Sentimiento:</b>
                      <SeverityPill color={statusMap[review.sentiment]}>
                        {review.sentiment}
                      </SeverityPill>
                    </Typography>
                    <Typography>
                      <b>Emoción:</b> {review.emotion}
                    </Typography>
                    <Typography>
                      <b>Grupo:</b> {review.group}
                    </Typography>
                    {review.improvement && (
                      <Typography>
                        <b>Mejora:</b> {review.improvement}
                      </Typography>
                    )}
                    <Typography>
                      <b>Puntaje:</b> {review.score}
                    </Typography>
                    <Typography>
                      <b>Resumen:</b> {review.summary}
                    </Typography>
                  </Stack>
                ))}
            </Stack>
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button variant="contained" onClick={handleClose}>
              Cerrar
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Modal>
  );
}
