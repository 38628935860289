import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Scrollbar } from '../scrollbar';
import { useEffect, useState } from 'react';
import { getSpotsByBranchApi } from '../../api/spots';
import { SeverityPill } from '../severity-pill';
import { useNavigate } from 'react-router-dom';

export const SpotsTable = ({ sx, branchId, triggerFetch, handleSuccess }) => {
  const navigate = useNavigate();

  const [spots, setSpots] = useState([]);

  const [filterOptions, setFilterOptions] = useState({
    active: true,
    inactive: false,
  });

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      [name]: checked,
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let statusList = [];
        if (filterOptions.active) statusList.push(true);
        if (filterOptions.inactive) statusList.push(false);
        const data = await getSpotsByBranchApi(branchId, statusList);
        setSpots(data);
      } catch (error) {
        console.error('Error fetching spots:', error);
      }
    };
    branchId && fetchData();
  }, [branchId, triggerFetch, filterOptions]);

  return (
    <Card sx={sx}>
      <CardHeader title="Espacios registrados" sx={{ padding: '15px' }} />
      <FormControl sx={{ m: 1 }} component="fieldset" variant="standard">
        <FormGroup row={true}>
          <FormControlLabel
            control={
              <Checkbox
                checked={filterOptions.active}
                onChange={handleChange}
                name="active"
              />
            }
            label="Activo"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filterOptions.inactive}
                onChange={handleChange}
                name="inactive"
              />
            }
            label="Inactivo"
          />
        </FormGroup>
      </FormControl>

      <Scrollbar sx={{ flexGrow: 1 }}>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sortDirection="desc">Nombre</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {spots.map((spot) => {
                return (
                  <TableRow hover key={spot.id}>
                    <TableCell
                      onClick={() => navigate(`/adminPanel/spot/${spot.id}`)}
                    >
                      {spot.name}
                    </TableCell>
                    <TableCell
                      onClick={() => navigate(`/adminPanel/spot/${spot.id}`)}
                    >
                      <SeverityPill color={spot.status ? 'success' : 'error'}>
                        {spot.status ? 'Activo' : 'Inactivo'}
                      </SeverityPill>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <Divider />
    </Card>
  );
};

SpotsTable.prototype = {
  branchId: PropTypes.number,
  sx: PropTypes.object,
};
