import { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { Layout as DashboardLayout } from '../layouts/dashboard/layout';
import { getStripeCustomerSessionIdApi } from '../api/stripe';
import { useAuthStore } from '../hooks/useAuthStore';

function PricingPage() {
  const { isAuthenticated } = useAuthStore();
  const [stripeCustomerSessionId, setStripeCustomerSessionId] = useState('');
  const lastCalledRef = useRef(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getStripeCustomerSessionIdApi();
        setStripeCustomerSessionId(data.customerSessionId);
      } catch (error) {
        console.error('Error fetching Stripe Customer Session Id:', error);
      }
    };

    const now = Date.now();
    if (isAuthenticated && now - lastCalledRef.current > 5000) {
      lastCalledRef.current = now;
      fetchData();
    }
  }, [isAuthenticated]);

  if (stripeCustomerSessionId === '') {
    return (
      <DashboardLayout>
        <Box
          sx={{
            backgroundColor: 'background.paper',
            flex: '1 1 auto',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <p>Loading...</p>
        </Box>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      {process.env.NODE_ENV === 'production' ? (
        <stripe-pricing-table
          pricing-table-id="prctbl_1PXtozL0qIRSwgqYxGdFTiCi"
          publishable-key="pk_live_51Or6bxL0qIRSwgqY10hcnbt8Fsxy0j0ZbpIBexxNxez2eQ2mwhzJy93vW3oIkHH1BVZAjoZrHW4BPnbi4rSsg6DN00QSQNJSto"
          customer-session-client-secret={stripeCustomerSessionId}
        />
      ) : (
        <stripe-pricing-table
          pricing-table-id="prctbl_1Pa23rL0qIRSwgqY5bccb6GQ"
          publishable-key="pk_test_51Or6bxL0qIRSwgqYSnAXwiIFkTJ8bzEoy7X0BlyDL9cyKjvbppohPoRNu8yWlK62kYkqJB2He2ObyuFo7UtweNfS00TwKmCfCK"
          customer-session-client-secret={stripeCustomerSessionId}
        />
      )}
    </DashboardLayout>
  );
}

export default PricingPage;
