import { Layout as DashboardLayout } from '../layouts/dashboard/layout';
import {
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { ToastSuccessSync } from '../components/shared/Toast';
import { useAuthStore } from '../hooks/useAuthStore';
import { ChangePasswordForm } from '../components/forms/change-password-form';
import EnvelopeIcon from '@heroicons/react/24/solid/EnvelopeIcon';
import { useState } from 'react';
import ConfirmationModal from '../components/modals/confirmationModal';
import { sendEmailVerif } from '../firebase/providers';

const MyInfo = () => {
  const { isLoading, firebaseUser, firebaseLoading, user } = useAuthStore();

  const [sendVerificationEmailDisabled, setSendVerificationEmailDisabled] =
    useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const handleOpenConfirmationModal = () => setConfirmationModalOpen(true);
  const handleCloseConfirmationModal = () => setConfirmationModalOpen(false);

  const handleSuccessPasswordReset = async () => {
    ToastSuccessSync('Contraseña reestablecida');
  };

  const handleSuccessDelete = async () => {
    handleCloseConfirmationModal();
    try {
      await sendEmailVerif();
      // wait for 30 seconds before letting the user try again
      ToastSuccessSync('Verificación enviada exitosamente');
      setSendVerificationEmailDisabled(true);
      setTimeout(() => {
        setSendVerificationEmailDisabled(false);
      }, 30000);
    } catch (error) {
      console.error('Error sending verification email:', error);
    }
  };

  return (
    <DashboardLayout>
      <ToastContainer />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">{user.name}</Typography>
              </Stack>
              {!isLoading && !firebaseLoading && !firebaseUser?.isVerified && (
                <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <EnvelopeIcon />
                    </SvgIcon>
                  }
                  sx={{ bgcolor: 'main', color: 'white' }}
                  variant="contained"
                  disabled={sendVerificationEmailDisabled}
                  onClick={handleOpenConfirmationModal}
                >
                  Reenviar verificación de correo electrónico
                </Button>
              )}
            </Stack>
            <ChangePasswordForm
              user={user}
              handleSuccess={handleSuccessPasswordReset}
            />
          </Stack>
        </Container>
      </Box>
      <ConfirmationModal
        open={confirmationModalOpen}
        confirmationText={`Al enviar este correo de verificación, recibirás a tu correo de registro instrucciones para poder verificarlo debidamente. No se puede reenviar este correo hasta 30 segundos después.`}
        handleClose={handleCloseConfirmationModal}
        handleSuccess={handleSuccessDelete}
      />
    </DashboardLayout>
  );
};

export default MyInfo;
