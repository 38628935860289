import {
  Box,
  Container,
  Unstable_Grid2 as Grid,
  Typography,
} from '@mui/material';
import { Layout as DashboardLayout } from '../layouts/dashboard/layout';
import { getReviewsApi } from '../api/reviews';
import { useEffect, useState, useRef } from 'react';
import { useAuthStore } from '../hooks/useAuthStore';
import { DatesFilter } from '../components/cards/dateFilter';
import { TotalReviews } from '../components/cards/total-reviews';
import { AIReport } from '../components/cards/ai-report';
import { BySentimentPie } from '../components/charts/pie-sentiment';
import { ReviewsWeek } from '../components/charts/reviews-week';
import { ReviewsHour } from '../components/charts/reviews-hour';
import { ReviewsYear } from '../components/charts/reviews-year';
import { ByEmotionPie } from '../components/charts/pie-emotion';
import { ByGroupPie } from '../components/charts/pie-group';
import { ReviewsByDateTable } from '../components/tables/reviews-by-date';

const Dashboard = () => {
  const [totalReviews, setTotalReviews] = useState(0);
  const [reviewsRawResponse, setReviewsRawResponse] = useState({});
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const lastCalledRef = useRef(0);

  const { currentBranch } = useAuthStore();

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    const fetchData = async (start, end) => {
      try {
        const data = await getReviewsApi(currentBranch.id, start, end);
        setTotalReviews(data.reviewsPage.totalReviews);
        setReviewsRawResponse(data);
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };
    const now = Date.now();
    if (
      startDate !== '' &&
      endDate !== '' &&
      currentBranch.id &&
      now - lastCalledRef.current > 5000
    ) {
      lastCalledRef.current = now;
      fetchData(startDate, endDate);
    }
  }, [startDate, endDate, currentBranch]);

  return (
    <DashboardLayout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Container maxWidth="xl">
          <Typography variant="h1">{currentBranch.name}</Typography>
          <Grid container spacing={3}>
            <Grid xs={12} lg={9}>
              <DatesFilter
                handleFilter={handleDateChange}
                sx={{ height: '100%' }}
              />
            </Grid>
            <Grid md={6} lg={3}>
              <TotalReviews
                sx={{ height: '100%' }}
                reviewsTotal={totalReviews}
              />
            </Grid>
            <Grid xs={12}>
              <AIReport sx={{ height: '100%' }} />
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              <BySentimentPie
                groupedBySentiment={reviewsRawResponse['groupBySentiment']}
                total={totalReviews}
                sx={{ height: '100%' }}
              />
            </Grid>
            <Grid xs={12} lg={8}>
              <ReviewsWeek
                startDate={startDate}
                endDate={endDate}
                branchId={currentBranch.id}
                sx={{ height: '100%' }}
              />
            </Grid>
            <Grid xs={8}>
              <ReviewsHour
                startDate={startDate}
                endDate={endDate}
                branchId={currentBranch.id}
                sx={{ height: '100%' }}
              />
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              <ByEmotionPie
                groupedByEmotion={reviewsRawResponse['groupByEmotion']}
                total={totalReviews}
                sx={{ height: '100%' }}
              />
            </Grid>
            <Grid xs={12} md={6} lg={4}>
              <ByGroupPie
                groupedByGroup={reviewsRawResponse['groupByGroup']}
                total={totalReviews}
                sx={{ height: '100%' }}
              />
            </Grid>
            <Grid xs={12} lg={8}>
              <ReviewsYear
                startDate={startDate}
                endDate={endDate}
                branchId={currentBranch.id}
                sx={{ height: '100%' }}
              />
            </Grid>
            <Grid xs={12} lg={8}>
              <ReviewsByDateTable
                startDate={startDate}
                endDate={endDate}
                branchId={currentBranch.id}
                sx={{ height: '100%' }}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </DashboardLayout>
  );
};

export default Dashboard;
