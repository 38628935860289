import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { NewSpotForm } from '../forms/new-spot-form';
import { NewAttendantForm } from '../forms/new-attendant-form';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  p: 4,
};

export default function AttendantOrSpotModal({
  open,
  modalType,
  handleClose,
  handleSuccess,
  branchId,
  newAttendant,
  attendant,
  newSpot,
  spot,
}) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {modalType === 'attendant' && (
            <NewAttendantForm
              newAttendant={newAttendant}
              attendant={attendant}
              branchId={branchId}
              handleSuccess={handleSuccess}
            />
          )}
          {modalType === 'spot' && (
            <NewSpotForm
              newSpot={newSpot}
              spot={spot}
              branchId={branchId}
              handleSuccess={handleSuccess}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
}
