export const colors = [
  '#FF5733', // Red
  '#3498DB', // Blue
  '#2ECC71', // Green
  '#F1C40F', // Yellow
  '#9B59B6', // Purple
  '#FFA500', // Orange
  '#00FFFF', // Cyan
  '#008080', // Teal
];
