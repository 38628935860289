import { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Unstable_Grid2 as Grid,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuthStore } from '../../hooks/useAuthStore';
import { getBusinessApi, patchBusinessApi } from '../../api/businesses';

export const AttendantsSpotsEnforcementForm = ({ handleSuccess }) => {
  const [modified, setModified] = useState(false);
  const { business, updateBusiness } = useAuthStore();
  const [fetchedBusiness, setFetchedBusiness] = useState({});
  const lastCalledRef = useRef(0);

  const formik = useFormik({
    initialValues: {
      enforceAttendants: false,
      enforceSpots: false,
      submit: null,
    },
    validationSchema: Yup.object({
      enforceAttendants: Yup.boolean().required('La definición es necesaria'),
      enforceSpots: Yup.boolean().required('La definición es necesaria'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const updatedBusiness = await patchBusinessApi(fetchedBusiness.id, {
          ...fetchedBusiness,
          enforceAttendants: values.enforceAttendants,
          enforceSpots: values.enforceSpots,
        });
        updateBusiness(updatedBusiness);
        helpers.resetForm();
        setModified(false);
        handleSuccess();
      } catch (error) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: error.message });
        helpers.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getBusinessApi(business.id);
        setFetchedBusiness(data);
        formik.setValues({
          enforceAttendants: data.enforceAttendants,
          enforceSpots: data.enforceSpots,
          submit: null,
        });
      } catch (error) {
        console.error('Error fetching business:', error);
      }
    };
    const now = Date.now();
    if (business.id && now - lastCalledRef.current > 5000) {
      console.log('AttendantsSpotsEnforcementForm - fetchData');
      lastCalledRef.current = now;
      fetchData();
    }

    // eslint-disable-next-line
  }, [business]);

  const handleChange = () => {
    setModified(true);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Card>
        <CardHeader
          title="Modificar formato de Reseña"
          subheader="Esto obliga o desobliga el uso de ciertos campos a la hora de realizar una reseña."
        />
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ m: -1.5 }}>
            <Grid container spacing={3}>
              <Grid xs={12} md={6}>
                <FormControlLabel
                  label="Obligar mención de asistente en reseña"
                  control={
                    <Checkbox
                      checked={formik.values.enforceAttendants}
                      value={formik.values.enforceAttendants}
                      name="enforceAttendants"
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        formik.handleChange(e);
                        handleChange();
                      }}
                    />
                  }
                />
              </Grid>
              <Grid xs={12} md={6}>
                <FormControlLabel
                  label="Obligar mención de espacio en reseña"
                  control={
                    <Checkbox
                      checked={formik.values.enforceSpots}
                      value={formik.values.enforceSpots}
                      name="enforceSpots"
                      onBlur={formik.handleBlur}
                      onChange={(e, v) => {
                        formik.handleChange(e, v);
                        handleChange();
                      }}
                    />
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
        {modified && (
          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button variant="contained" type="submit">
              Actualizar
            </Button>
          </CardActions>
        )}
      </Card>
    </form>
  );
};
