import PropTypes from 'prop-types';
import ChartBarIcon from '@heroicons/react/24/solid/ChartBarIcon';
import TableCellsIcon from '@heroicons/react/24/solid/TableCellsIcon';
import { Button, Card, CardContent, SvgIcon } from '@mui/material';

export const TableGraphToggle = ({ sx, tableGraphToggle, handleToggle }) => {
  return (
    <Card sx={sx}>
      <CardContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {!tableGraphToggle ? (
          <Button onClick={handleToggle} variant="contained" size="small">
            Gráfica
            <SvgIcon sx={{ marginLeft: '8px' }}>
              <ChartBarIcon />
            </SvgIcon>
          </Button>
        ) : (
          <Button onClick={handleToggle} variant="contained" size="small">
            Tabla
            <SvgIcon sx={{ marginLeft: '8px' }}>
              <TableCellsIcon />
            </SvgIcon>
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

TableGraphToggle.propTypes = {
  sx: PropTypes.object,
};
