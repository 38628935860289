import {
  signInWithEmailAndPassword,
  sendEmailVerification,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { FirebaseAuth } from './config';

export const signInUserWithEmailPassword = async ({ email, password }) => {
  return await signInWithEmailAndPassword(FirebaseAuth, email, password);
};

export const createUserWithEmailAndPass = async ({ email, password }) => {
  return await createUserWithEmailAndPassword(FirebaseAuth, email, password);
};

export const sendEmailVerif = async () => {
  const user = FirebaseAuth.currentUser;
  if (user) {
    await sendEmailVerification(user)
      .then(() => {
        console.log('Verification email sent.');
      })
      .catch((error) => {
        throw new Error('Error sending verification email:', error);
      });
  } else {
    throw new Error('No user is signed in.');
  }
};

export const logoutFirebase = async () => {
  await FirebaseAuth.signOut();
};
