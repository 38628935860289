import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useAuthStore } from '../hooks/useAuthStore';
import { useNavigate } from 'react-router-dom';
import { getGroupsCollections } from '../api/groupsCollections';
import { useEffect, useState } from 'react';

const SignInPage = () => {
  const [currentGroup, setCurrentGroup] = useState({});
  const [groupsCollections, setGroupsCollections] = useState([]);

  const { startSignUp } = useAuthStore();
  const navigate = useNavigate();

  const handleGroupsCollectionsChange = async (e, values) => {
    const groupName = values.props.value;
    const groupsCollectionObj = groupsCollections.find(
      (groupsCollection) => groupsCollection.name === groupName
    );
    setCurrentGroup(groupsCollectionObj);
  };

  useEffect(() => {
    const fetchGroupsCollections = async () => {
      try {
        const data = await getGroupsCollections();
        setGroupsCollections(data);
      } catch (error) {
        console.error('Error fetching parentReview:', error);
      }
    };
    fetchGroupsCollections();
  }, []);

  // const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      username: '',
      email: '',
      password: '',
      passwordVal: '',
      businessName: '',
      businessDescription: '',
      branchName: '',
      branchAddress: '',
      branchGroups: '',
      submit: null,
    },
    validationSchema: Yup.object({
      username: Yup.string().max(255).required('Inserta Nombre del Usuario'),
      email: Yup.string()
        .email('Necesita ser un correo electrónico válido')
        .required('Inserta un correo electrónico'),
      password: Yup.string()
        .max(255)
        .min(8, 'Mínimo 8 cacrcteres')
        .required('Inserta Contraseña'),
      passwordVal: Yup.string()
        .max(255)
        .min(8, 'Mínimo 8 cacrcteres')
        .required('Inserta Contraseña de Validación'),
      businessName: Yup.string().max(255).required('Inserta Nombre de Empresa'),
      businessDescription: Yup.string()
        .max(255)
        .required('Inserta Descripción de Empresa'),
      branchName: Yup.string().max(255).required('Inserta Nombre de Sucursal'),
      branchAddress: Yup.string()
        .max(255)
        .required('Inserta Dirección de Sucursal'),
      branchGroups: Yup.string().max(255).required('Selecciona un grupo'),
    }),
    onSubmit: async (values, helpers) => {
      if (values.password !== values.passwordVal) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: 'Password Validation Failed' });
        helpers.setSubmitting(false);
        return;
      }
      try {
        await startSignUp({ ...values, branchGroups: currentGroup.groups });
        navigate('/pricing');
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <>
      <Box
        sx={{
          backgroundColor: 'background.paper',
          flex: '1 1 auto',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            maxWidth: 550,
            px: 3,
            py: '100px',
            width: '100%',
          }}
        >
          <div>
            <Stack
              sx={{
                alignItems: 'center',
              }}
            >
              <img
                src="/tdd_logo_negro.png"
                width={295}
                height={76}
                alt="Logo"
              />
            </Stack>
            <Stack spacing={1} sx={{ mb: 3 }}>
              <Typography variant="h4">Registro</Typography>
              <Typography color="text.secondary" variant="body2">
                ¿Ya tienes una cuenta? <a href="/login">Log In</a>
              </Typography>
            </Stack>
            <form noValidate onSubmit={formik.handleSubmit}>
              <Stack spacing={3}>
                <TextField
                  fullWidth
                  label="Nombre"
                  name="username"
                  error={!!(formik.touched.username && formik.errors.username)}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.username}
                />
                <TextField
                  error={!!(formik.touched.email && formik.errors.email)}
                  fullWidth
                  helperText={formik.touched.email && formik.errors.email}
                  label="Email Address"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="email"
                  value={formik.values.email}
                />
                <TextField
                  error={!!(formik.touched.password && formik.errors.password)}
                  fullWidth
                  helperText={formik.touched.password && formik.errors.password}
                  label="Password"
                  name="password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="password"
                  value={formik.values.password}
                />
                <TextField
                  fullWidth
                  label="Contraseña Validación"
                  name="passwordVal"
                  type="password"
                  error={
                    !!(formik.touched.passwordVal && formik.errors.passwordVal)
                  }
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.passwordVal}
                />
                {/* BUSINESS STUFF */}
                <TextField
                  fullWidth
                  label="Nombre del negocio"
                  name="businessName"
                  error={
                    !!(
                      formik.touched.businessName && formik.errors.businessName
                    )
                  }
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.businessName}
                />
                <TextField
                  fullWidth
                  label="Descripción"
                  name="businessDescription"
                  minRows={3}
                  multiline
                  error={
                    !!(
                      formik.touched.businessDescription &&
                      formik.errors.businessDescription
                    )
                  }
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.businessDescription}
                />
                {/* BRANCH STUFF */}
                <TextField
                  fullWidth
                  label="Nombre de la sucursal"
                  name="branchName"
                  error={
                    !!(formik.touched.branchName && formik.errors.branchName)
                  }
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.branchName}
                />
                <TextField
                  fullWidth
                  label="Dirección"
                  name="branchAddress"
                  minRows={3}
                  multiline
                  error={
                    !!(
                      formik.touched.branchAddress &&
                      formik.errors.branchAddress
                    )
                  }
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.branchAddress}
                />
                <InputLabel>Colección</InputLabel>
                <Select
                  name="branchGroups"
                  error={
                    !!(
                      formik.touched.branchGroups && formik.errors.branchGroups
                    )
                  }
                  fullWidth
                  value={currentGroup.name || ''}
                  onChange={(e, values) => {
                    formik.handleChange(e);
                    handleGroupsCollectionsChange(e, values);
                  }}
                >
                  {groupsCollections.map((groupsCollection) => (
                    <MenuItem
                      key={groupsCollection.id}
                      value={groupsCollection.name}
                    >
                      {groupsCollection.name}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
              {formik.errors.submit && (
                <Typography color="error" sx={{ mt: 3 }} variant="body2">
                  {formik.errors.submit}
                </Typography>
              )}
              <Button
                fullWidth
                size="large"
                sx={{ mt: 3 }}
                type="submit"
                variant="contained"
              >
                Registrarse
              </Button>
            </form>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default SignInPage;
