import PropTypes from 'prop-types';
import { Button, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { SeverityPill } from '../../components/severity-pill';

export const GroupsCard = ({
  groups = [],
  handleSelectGroup,
  selectedGroup,
}) => {
  useEffect(() => {
    if (!groups.length) return;

    if (selectedGroup) {
      handleSelectGroup(selectedGroup);
    } else {
      handleSelectGroup(groups[0]);
    }
  }, [handleSelectGroup, selectedGroup, groups]);

  return (
    <Stack
      alignItems="flex-start"
      direction="row"
      justifyContent="space-between"
      spacing={3}
    >
      {groups.map((group, index) => (
        <Button
          key={index}
          onClick={() => {
            handleSelectGroup(group);
          }}
        >
          <Stack spacing={1}>
            {group === selectedGroup ? (
              <SeverityPill color="success">
                <Typography color="text.primary" variant="overline">
                  {group}
                </Typography>
              </SeverityPill>
            ) : (
              <Typography color="text.primary" variant="overline">
                {group}
              </Typography>
            )}
          </Stack>
        </Button>
      ))}
    </Stack>
  );
};

GroupsCard.propTypes = {
  groups: PropTypes.array,
  sx: PropTypes.object,
};
