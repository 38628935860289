import { Layout as DashboardLayout } from '../../layouts/dashboard/layout';
import {
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material';
import PlusIcon from '@heroicons/react/24/solid/PlusIcon';
import { BranchesTable } from '../../components/tables/branches-table';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { applyPagination } from '../../utils/apply-pagination';
import BranchModal from '../../components/modals/branchModal';
import { ToastContainer } from 'react-toastify';
import { ToastSuccessSync } from '../../components/shared/Toast';
import { useAuthStore } from '../../hooks/useAuthStore';
import { getBranchesByBusinessApi, getUserBranchesApi } from '../../api/branch';

const AdminPanel = () => {
  const { business, branches, updateBranches, user } = useAuthStore();

  const usePagedBranches = (page, rowsPerPage) => {
    return useMemo(
      () => {
        return applyPagination(filteredBranches, page, rowsPerPage);
      },
      // eslint-disable-next-line
      [page, rowsPerPage, filteredBranches]
    );
  };

  const [filteredBranches, setFilteredBranches] = useState([]);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openModal, setOpenModal] = useState(false);
  const pagedBranches = usePagedBranches(page, rowsPerPage);
  const lastCalledRef = useRef(0);
  // const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleSuccess = () => {
    handleCloseModal();
    ToastSuccessSync('Procedimiento Exitoso');
    setTriggerFetch((prevState) => !prevState);
  };

  const handlePageChange = useCallback((event, value) => {
    setPage(value);
  }, []);

  const handleRowsPerPageChange = useCallback((event) => {
    setRowsPerPage(event.target.value);
  }, []);

  const handleFilters = (filters) => {
    const filteredResult = branches.filter((branch) => {
      if (filters.active && !branch.status) return false;
      if (filters.inactive && branch.status) return false;
      return true;
    });
    setFilteredBranches(filteredResult);
  };

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const branches =
          user.role === 'OWNER'
            ? await getBranchesByBusinessApi(business.id)
            : await getUserBranchesApi();
        updateBranches(branches);
        setFilteredBranches(branches);
      } catch (error) {
        console.error('Error fetching branches:', error);
      }
    };
    const now = Date.now();
    if (
      business.id &&
      user.id &&
      (now - lastCalledRef.current > 5000 || triggerFetch)
    ) {
      lastCalledRef.current = now;
      fetchBranches();
    }

    // eslint-disable-next-line
  }, [triggerFetch, business, user]);

  return (
    <DashboardLayout>
      <ToastContainer />

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">Sucursales</Typography>
              </Stack>
              <div>
                <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  // TODO: Logic to Limit Branch Creation
                  // onClick={handleOpenModal}
                >
                  Agregar Nueva Sucursal
                </Button>
              </div>
            </Stack>
            <BranchesTable
              count={branches.length}
              items={pagedBranches}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              handleFilters={handleFilters}
            />
          </Stack>
        </Container>
      </Box>

      <BranchModal
        open={openModal}
        businessId={business.id}
        handleClose={handleCloseModal}
        handleSuccess={handleSuccess}
      />
    </DashboardLayout>
    // </RoleGuard>
  );
};

export default AdminPanel;
