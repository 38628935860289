import React, { lazy, Suspense } from 'react';
import { styled } from '@mui/material/styles';

const ApexChart = lazy(() => import('react-apexcharts'));

const StyledApexChart = styled(ApexChart)``;

export const Chart = (props) => (
  <Suspense fallback={<div>Loading...</div>}>
    <StyledApexChart {...props} />
  </Suspense>
);
