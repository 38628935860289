import { Layout as DashboardLayout } from '../../layouts/dashboard/layout';
import {
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { NewSpotForm } from '../../components/forms/new-spot-form';
import { deleteSpotApi, getSpotByIdApi } from '../../api/spots';
import { ToastContainer } from 'react-toastify';
import { ToastSuccessSync } from '../../components/shared/Toast';
import { useNavigate, useParams } from 'react-router-dom';
import TrashIcon from '@heroicons/react/24/solid/TrashIcon';
import ConfirmationModal from '../../components/modals/confirmationModal';

const Spot = () => {
  const { spotId } = useParams();
  const navigate = useNavigate();

  const [spot, setSpot] = useState({});
  const lastCalledRef = useRef(0);
  const [triggerFetch, setTriggerFetch] = useState(false);

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const handleOpenConfirmationModal = () => setConfirmationModalOpen(true);
  const handleCloseConfirmationModal = () => setConfirmationModalOpen(false);

  const handleSuccess = () => {
    ToastSuccessSync('Procedimiento Exitoso');
    setTriggerFetch((prevState) => !prevState);
  };

  const handleSuccessDelete = async () => {
    const sleep = (milliseconds) => {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    };
    handleCloseConfirmationModal();
    try {
      await deleteSpotApi(spotId);
      ToastSuccessSync('Procedimiento Exitoso');
      sleep(1000).then(() => {
        navigate(`/adminPanel/branches/${spot.branchId}`);
      });
    } catch (error) {
      console.error('Error deleting spot:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getSpotByIdApi(spotId);
        setSpot(data);
      } catch (error) {
        console.error('Error fetching spot:', error);
      }
    };
    const now = Date.now();
    if (spotId && (now - lastCalledRef.current > 5000 || triggerFetch)) {
      lastCalledRef.current = now;
      fetchData();
    }
  }, [triggerFetch, spotId]);

  return (
    <DashboardLayout>
      <ToastContainer />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">{spot.name}</Typography>
              </Stack>
              <Button
                startIcon={
                  <SvgIcon fontSize="small">
                    <TrashIcon />
                  </SvgIcon>
                }
                sx={{ bgcolor: 'error.main', color: 'white' }}
                variant="contained"
                onClick={handleOpenConfirmationModal}
              >
                Borrar Espacio
              </Button>
            </Stack>
            <NewSpotForm
              newSpot={false}
              spot={spot}
              handleSuccess={handleSuccess}
            />
          </Stack>
        </Container>
      </Box>
      <ConfirmationModal
        open={confirmationModalOpen}
        confirmationText={`¿Estás seguro que deseas borrar el espacio ${spot.name}? Toda la información relacionada con este será eliminada sin excepción.`}
        handleClose={handleCloseConfirmationModal}
        handleSuccess={handleSuccessDelete}
      />
    </DashboardLayout>
  );
};

export default Spot;
